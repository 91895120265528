import apolloUtil from '@/service/ApolloUtil'

import vehicleRequestQuery from '@/service/vehicleRequest/VehicleRequestQuery.gql'
import saveVehicleRequestMutation from '@/service/vehicleRequest/SaveVehicleRequestMutation.gql'
import deleteVehicleRequestMutation from '@/service/vehicleRequest/DeleteVehicleRequestMutation.gql'

class VehicleRequestService {

  search(params) {
    return apolloUtil.query(vehicleRequestQuery, params)
      .then((response) => response?.data?.vehicleRequests)
  }

  save(params) {
    return apolloUtil.mutate(saveVehicleRequestMutation, params, false)
  }

  delete(params) {
    return apolloUtil.mutate(deleteVehicleRequestMutation, params)
  }

}

export default new VehicleRequestService()
