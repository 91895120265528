<template>
  <v-row class="ma-0" justify="center">
    <!-- loading -->
    <v-progress-circular v-if="loading" :size="15" color="main" indeterminate />

    <template v-else>
      <!-- edit request dialog -->
      <v-dialog v-model="showDialog"
                @click:outside="closeHandler"
                @keydown="value => value.key === 'Escape' ? closeHandler() : ''"
                scrollable max-width="700px">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="showDialog = true">mdi-square-edit-outline</v-icon>
        </template>

        <v-card class="pa-1">
          <!-- title -->
          <v-card-title class="d-flex justify-center">
            <h3 class="main--text">{{ translations.editRequest }}</h3>
          </v-card-title>

          <v-card-text>
            <!-- section vehicle + equipment / note -->
            <div class="section-divider mt-1 pb-6">
              <div class="section-divider-title">{{ item.vehicle === null ? `${translations.equipment}` : `${translations.vehicles}` }}</div>

              <!-- vehicles -->
              <v-col class="pa-0 mt-1" v-if="item.vehicle !== null">
                <b class="ml-8">{{ translations.vehicles }}</b>
                <p class="mb-0 mt-1">
                  <v-icon class="mr-1">mdi-truck</v-icon>
                  {{ `${item.vehicle.code} - ${item.vehicle.label}` }}
                </p>
              </v-col>

              <!-- equipment / note -->
              <v-col class="pa-0 mt-6">
                <b class="ml-8">{{ item.vehicle === null ? `${translations.notes} / ${translations.equipment}` : `${translations.notes}` }}</b>
                <v-textarea v-model="note" prepend-icon="mdi-text-long" filled flat dense hide-details></v-textarea>
              </v-col>
            </div>


            <!-- section type + priority + date planned -->
            <div class="section-divider mt-12">
              <div class="section-divider-title">{{ translations.operationType }}</div>

              <!-- type -->
              <v-col class="pa-0 mt-1">
                <b class="ml-8">{{ translations.operationType }} *</b>
                <v-select v-model="type" :items="$store.getters.vehicleOperations"
                          prepend-icon="mdi-power-socket"
                          outlined dense hide-details>
                  <template v-slot:item="data">
                    {{ data.item.text }}
                  </template>
                </v-select>
              </v-col>

              <v-row class="ma-0 pa-0" justify="space-between">
                <!-- priority -->
                <v-col class="pa-0 mt-6" cols="5">
                  <b class="ml-8">{{ translations.priority }}</b>
                  <v-select v-model="priority" :items="$store.getters.prioritiesSelect"
                            prepend-icon="mdi-priority-high"
                            outlined dense hide-details>
                    <template v-slot:selection="{ item }">
                      <v-chip small label class="status-chip" :color="item.background">
                        <div :style="[{ color: item.color }]">{{ item.text }}</div>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-chip small label class="status-chip" :color="item.background">
                        <div :style="[{ color: item.color }]">{{ item.text }}</div>
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>

                <!-- date planned -->
                <v-col class="pa-0 mt-6" cols="6">
                  <b class="ml-8">{{ translations.plannedDate }}</b>
                  <date-picker @selected-date="value => setDate(value, 'datePlanned')" :dateProp="datePlanned" :classToAdd="['date-picker-component']" :disablePicker="false"></date-picker>
                </v-col>
              </v-row>
            </div>


            <!-- section status -->
            <div class="section-divider mt-12">
              <div class="section-divider-title">{{ translations.details }}</div>

              <!-- status -->
              <v-col class="pa-0 mt-1">
                <b class="ml-8">{{ translations.status }}</b>
                <v-select v-model="status" :items="$store.getters.vehicleRequestStatusesSelect"
                          @change="statusChangeHandler"
                          prepend-icon="mdi-progress-check"
                          outlined dense hide-details>
                  <template v-slot:selection="{ item }">
                    <v-chip small label class="status-chip" :color="item.background">
                      <div :style="[{ color: item.color }]">{{ item.text }}</div>
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-chip small label class="status-chip" :color="data.item.background">
                      <div :style="[{ color: data.item.color }]">{{ data.item.text }}</div>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>


              <v-row v-if="allowDatesSelection" id="dates-row" class="ma-0 pa-0" justify="space-between">
                <!-- date from -->
                <v-col class="pa-0 mt-6" cols="5">
                  <b class="ml-8">{{ translations.dateFrom }}</b>
                  <date-picker @selected-date="value => setDate(value, 'dateFrom')" :dateProp="dateFrom" :classToAdd="['date-picker-component']" :disablePicker="false"></date-picker>
                </v-col>

                <!-- date to -->
                <v-col class="pa-0 mt-6" cols="6">
                  <b class="ml-8">{{ translations.toDate }}</b>
                  <date-picker @selected-date="value => setDate(value, 'dateTo')" :dateProp="dateTo" :minDateProp="dateFrom.picker" :startToDate="startToDate" :classToAdd="['date-picker-component']" :disablePicker="false"></date-picker>
                </v-col>
              </v-row>
            </div>


            <!-- created at + last update -->
            <v-col class="pa-0 mt-8 d-flex justify-space-between">
              <!-- created at -->
              <div>
                <b>{{ translations.createdAt }}</b>
                <p class="mb-0 mt-1">{{ formatFETimestamp(item.createdAtStr) }}</p>
              </div>

              <!-- last update -->
              <div class="text-end">
                <b>{{ translations.lastUpdate }}</b>
                <p class="mb-0 mt-1">{{ formatFETimestamp(item.lastUpdateStr) }}</p>
                <p class="mb-0 mt-1">{{ item.lastUpdateBy }}</p>
              </div>
            </v-col>
          </v-card-text>


          <!-- invalid form message -->
          <v-row v-if="displayDateError" class="pa-0 mx-0 mt-2" justify="center" align="center">
            <v-icon color="error" class="mr-2" small>mdi-alert</v-icon>

            <span class="error--text" style="font-size: .9rem">{{ translations.fromToDateError }}</span>
          </v-row>


          <v-card-actions class="pb-4 pt-4">
            <v-row class="ma-0" justify="center">
              <v-btn @click="updateVehicleRequest"
                     :disabled="disableBtn" :loading="loading"
                     color="main" class="white--text px-8">
                {{ translations.save }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <!-- delete -->
      <v-icon @click="deleteVehicleRequest"
              small color="error lighten-1" class="cursor-pointer">
        mdi-delete-outline
      </v-icon>


      <!-- message dialog -->
      <message-dialog :show="showErrorMsgDialog">
        <template v-slot:card-image>
          <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
            <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
          </div>
        </template>

        <template v-slot:message>
          <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
            {{ translations.overlappingDatesError }}
          </div>
        </template>

        <template v-slot:card-action>
          <div class="d-flex justify-center cursor-pointer" style="width: 100%">
            <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
          </div>
        </template>
      </message-dialog>
    </template>
  </v-row>
</template>


<script>
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import VehicleRequestService from '@/service/vehicleRequest/VehicleRequestService'

import DatePicker from '@/components/DatePicker'
import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'VehicleRequestEdit',

  components: {
    DatePicker,
    MessageDialog,
    WarningSvg
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      showDialog: false,
      loading: false,
      showErrorMsgDialog: false,

      type: this.item.type,
      priority: this.item.priority,
      status: this.item.status,
      dateFrom: {
        simple: this.item.dateFromStr,
        holder: this.momentDate(this.item.dateFromStr, 'DD/MM/YYYY'),
        picker: this.item.dateFromStr
      },
      dateTo: {
        simple: this.item.dateToStr,
        holder: this.momentDate(this.item.dateToStr, 'DD/MM/YYYY'),
        picker: this.item.dateToStr
      },
      datePlanned: {
        simple: this.item.datePlannedStr,
        holder: this.momentDate(this.item.datePlannedStr, 'DD/MM/YYYY'),
        picker: this.item.datePlannedStr
      },
      startToDate: this.item.dateFromStr,
      note: this.item.note
    }
  },

  computed: {
    ...mapState({
      accountId: state => state.auth.userInfo.account.id
    }),

    dateFromIsAfterTo() {
      // if none is present return false
      if (!this.dateFrom.simple && !this.dateTo.simple) {
        return false
      }

      // if only one is present return true we need both or none
      if ((!this.dateFrom.simple && this.dateTo.simple) || (this.dateFrom.simple && !this.dateTo.simple)) {
        return true
      }

      return this.dateFrom.simple > this.dateTo.simple
    },

    displayDateError() {
      return this.dateFrom.simple && this.dateTo.simple && this.dateFromIsAfterTo
    },

    disableBtn() {
      return !this.type || this.dateFromIsAfterTo
    },

    allowDatesSelection() {
      return this.item.vehicle
        && this.$store.getters.vehicleOperations.find(vo => vo.value === this.type).requestable
        && this.$store.getters.statusToAllowShowDate.includes(this.status)
    }
  },

  mixins: [MomentMixin],

  methods: {
    setDate(value, type) {
      this[type].simple = value.simple
      this[type].holder = this.momentDate(value.simple, 'DD/MM/YYYY')
      this[type].picker = this.formatDate(value.simple)

      if (type === 'dateFrom') {
        this.startToDate = value.picker
      }
    },

    closeHandler() {
      this.dateFrom = {
        simple: this.item.dateFromStr,
        holder: this.momentDate(this.item.dateFromStr, 'DD/MM/YYYY'),
        picker: this.item.dateFromStr
      }
      this.dateTo = {
        simple: this.item.dateToStr,
        holder: this.momentDate(this.item.dateToStr, 'DD/MM/YYYY'),
        picker: this.item.dateToStr
      }
    },

    async updateVehicleRequest() {
      this.loading = true

      const vehicleRequestObject = {
        id: this.item.id,
        accountId: this.accountId,
        type: this.type,
        dateFromStr: !this.allowDatesSelection ? null : this.dateFrom.simple,
        dateToStr: !this.allowDatesSelection ? null : this.dateTo.simple,
        note: this.note,
        status: this.status,
        priority: this.priority,
        datePlannedStr: this.datePlanned.simple
      }

      if (this.item?.vehicle?.id) {
        vehicleRequestObject['vehicleId'] = this.item?.vehicle?.id
      }

      await VehicleRequestService.save({
          vehicleRequestObject
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.showDialog = false
          this.closeHandler()
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
        })
    },

    async deleteVehicleRequest() {
      this.loading = true
      await VehicleRequestService.delete({
          vehicleRequestId: this.item.id,
          accountId: this.accountId
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.deletedSuccess })
          this.showDialog = false
          this.closeHandler()
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
        })
    },

    // when status change check if dates are shown if true scroll to dates row to show
    statusChangeHandler() {
      setTimeout(() => {
        if (this.allowDatesSelection) {
          document.querySelector('#dates-row').scrollIntoView({
            behavior: 'smooth'
          })
        }
      }, 100)
    }
  },

  watch: {
    showDialog: function(newVal) {
      this.note = this.item.note
      this.type = this.item.type
      if (newVal) {
        this.dateFrom = {
          simple: this.item.dateFromStr,
          holder: this.momentDate(this.item.dateFromStr, 'DD/MM/YYYY'),
          picker: this.item.dateFromStr
        }
        this.dateTo = {
          simple: this.item.dateToStr,
          holder: this.momentDate(this.item.dateToStr, 'DD/MM/YYYY'),
          picker: this.item.dateToStr
        }
      }
    }
  }
}
</script>
