<template>
  <v-row no-gutters>
    <v-col>
      <smart-autocomplete property-to-filter-by="codeOrLabel"
                          label="selectVehicle"
                          :key="`vehicle-smart-${keyIndex}`"
                          :value="vehicle"
                          :search-function="searchVehiclesFunction"
                          :custom-filter-function="customAutocompleteFilter"
                          :selection-apply-custom-text="false"
                          :applySecondaryListItem="true"
                          :prepend-icon="prependIcon ? prependIcon : ''"
                          item-value="id"
                          item-text="code"
                          itemTitle="code"
                          itemSubtitle="label"
                          class="mr-2"
                          style="min-width: 180px"
                          @set-value="handleVehicle">
      </smart-autocomplete>
      <span style="font-weight: bold; font-size: 12px" :class="[prependIcon ? 'ml-8' : '']">{{ computedLabel }}</span>
    </v-col>
  </v-row>
</template>


<script>
import SmartAutocomplete from '@/components/SmartAutocomplete'
import VehicleService from '@/service/vehicle/VehicleService'

export default {
  name: 'SmartAutocompleteVehicles',

  components: {
    SmartAutocomplete
  },

  props: {
    vehicle: {
      type: Object,
      required: true
    },
    // this is used if the query should filter out vehicles that have vehicle activities
    applyDateFilter: {
      type: Boolean,
      default: false
    },

    // date to filter vehicles that have vehicle activities
    dateStr: {
      type: String,
      required: false
    },

    // when using component in a loop use keyIndex to set proper key to component
    keyIndex: {
      type: Number,
      default: 0
    },

    prependIcon: {
      type: String,
      required: false
    }
  },

  methods: {
    searchVehiclesFunction(params) {
      const payload = {
        ...params,
        sort: 'v.code',
        order: 'ASC',
        applyDateFilter: this.applyDateFilter,
        dateStr: this.dateStr
      }
      return VehicleService.searchVehicles(payload)
    },

    handleVehicle(vehicle) {
      this.$emit('set-value', !vehicle ? {} : vehicle)
    },

    customAutocompleteFilter(item, queryText) {
      const text = queryText.toLowerCase()
      return (
        item.code?.toLowerCase().includes(text) ||
        item.label?.toLowerCase().includes(text)
      )
    }
  },

  computed: {
    computedLabel() {
      return this.vehicle.label
    }
  }
}
</script>
