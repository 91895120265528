import apolloUtil from '@/service/ApolloUtil'

import activityRequestQuery from '@/service/activityRequest/ActivityRequestsQuery.gql'
import saveActivityRequestMutation from '@/service/activityRequest/SaveActivityRequestMutation.gql'
import handleActivityRequestMutation from '@/service/activityRequest/HandleActivityRequestMutation.gql'
import deleteActivityRequestMutation from '@/service/activityRequest/DeleteActivityRequestMutation.gql'

class ActivityRequestService {

  search(params) {
    return apolloUtil.query(activityRequestQuery, params)
      .then((response) => response?.data?.activityRequests)
  }

  save(params) {
    return apolloUtil.mutate(saveActivityRequestMutation, params, false)
  }

  change(params) {
    return apolloUtil.mutate(handleActivityRequestMutation, params)
  }

  delete(params) {
    return apolloUtil.mutate(deleteActivityRequestMutation, params)
  }

}

export default new ActivityRequestService()
