import apolloUtil from '@/service/ApolloUtil'

import dpiQuery from '@/service/dpi/DpiQuery.gql'
import saveOrUpdateDpiMutation from '@/service/dpi/SaveOrUpdateDpiMutation.gql'
import deleteDpiMutation from '@/service/dpi/DeleteDpiMutation.gql'

class DpiService {

  search(params) {
    return apolloUtil.query(dpiQuery, params)
      .then((response) => response?.data?.dpis)
  }

  save(params) {
    return apolloUtil.mutate(saveOrUpdateDpiMutation, params, false)
  }

  delete(params) {
    return apolloUtil.mutate(deleteDpiMutation, params)
  }

}

export default new DpiService()
