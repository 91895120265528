import apolloUtil from '@/service/ApolloUtil'

import vehicleListQuery from '@/service/vehicle/VehicleListQuery.gql'
import vehicleSearchQuery from '@/service/vehicle/VehicleSearchQuery.gql'
import saveVehicleMutation from '@/service/vehicle/SaveVehicleMutation.gql'
import deleteVehicleMutation from '@/service/vehicle/DeleteVehicleMutation.gql'
import checkActiveVehicleWithKeyCodeQuery from '@/service/vehicle/CheckActiveVehicleWithKeyCodeQuery.gql'
import checkActiveVehicleWithCodeQuery from '@/service/vehicle/CheckActiveVehicleWithCodeQuery.gql'

class VehicleService {

  search(params) {
    return apolloUtil.query(vehicleListQuery, params)
      .then((response) => response?.data?.vehicleList)
  }

  searchVehicles(params){
    return apolloUtil.query(vehicleSearchQuery, params).then((response) => response.data.vehiclesSearch)
  }

  save(params) {
    return apolloUtil.mutate(saveVehicleMutation, params)
      .then((response) => response?.data?.saveVehicle)
  }

  delete(params) {
    return apolloUtil.mutate(deleteVehicleMutation, params)
  }

  checkKeyCode(keyCode) {
    return apolloUtil.query(checkActiveVehicleWithKeyCodeQuery, keyCode)
      .then((response) => response?.data?.isVehicleActiveWithKeyCode)
  }

  checkCode(code) {
    return apolloUtil.query(checkActiveVehicleWithCodeQuery, code)
      .then((response) => response?.data?.isVehicleActiveWithCode)
  }

}

export default new VehicleService()
