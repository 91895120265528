<template>
  <v-row class="ma-0" justify="center">
    <!-- new request dialog -->
    <v-dialog v-model="showDialog"
              @click:outside="closeHandler"
              @keydown="value => value.key === 'Escape' ? closeHandler() : ''"
              scrollable max-width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" @click="showDialog = true" color="main" class="white--text" small depressed>
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ translations.newButton }}
        </v-btn>
      </template>

      <v-card class="pa-1">
        <!-- title -->
        <v-card-title class="d-flex justify-center">
          <h3 class="main--text">{{ translations.newRequest }}</h3>
        </v-card-title>

        <v-card-text>
          <!-- section vehicle + equipment / note -->
          <div class="section-divider mt-1 pb-6">
            <div class="section-divider-title">{{ `${translations.vehicles} / ${translations.equipment}` }}</div>

            <!-- vehicles -->
            <v-col class="pa-0 mt-1">
              <b class="ml-8">{{ translations.vehicles }}</b>
              <smart-autocomplete-vehicles :key="`vehicle-smart-autocomplete`"
                :vehicle="selectedVehicle"
                prepend-icon="mdi-truck"
                @set-value="setVehicle">
              </smart-autocomplete-vehicles>
            </v-col>

            <!-- equipment / note -->
            <v-col class="pa-0 mt-6">
              <b class="ml-8">{{ translations.notes + ' / ' + translations.equipments }}</b>
              <v-textarea v-model="note" prepend-icon="mdi-text-long" filled flat dense hide-details></v-textarea>
            </v-col>
          </div>


          <!-- section type + priority + date planned -->
          <div class="section-divider mt-12">
            <div class="section-divider-title">{{ translations.operationType }}</div>

            <!-- type -->
            <v-col class="pa-0 mt-1">
              <b class="ml-8">{{ translations.operationType }} *</b>
              <v-select v-model="type" :items="$store.getters.vehicleOperations"
                        prepend-icon="mdi-power-socket"
                        outlined dense hide-details>
                <template v-slot:item="data">
                  {{ data.item.text }}
                </template>
              </v-select>
            </v-col>

            <v-row class="ma-0 pa-0" justify="space-between">
              <!-- priority -->
              <v-col class="pa-0 mt-6" cols="5">
                <b class="ml-8">{{ translations.priority }}</b>
                <v-select v-model="priority" :items="$store.getters.prioritiesSelect"
                          prepend-icon="mdi-priority-high"
                          outlined dense hide-details>
                  <template v-slot:selection="{ item }">
                    <v-chip small label class="status-chip" :color="item.background">
                      <div :style="[{ color: item.color }]">{{ item.text }}</div>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-chip small label class="status-chip" :color="item.background">
                      <div :style="[{ color: item.color }]">{{ item.text }}</div>
                    </v-chip>
                  </template>
                </v-select>
              </v-col>

              <!-- date planned -->
              <v-col class="pa-0 mt-6" cols="6">
                <b class="ml-8">{{ translations.plannedDate }}</b>
                <date-picker @selected-date="value => setDate(value, 'datePlanned')" :dateProp="datePlanned" :classToAdd="['date-picker-component']" :disablePicker="false"></date-picker>
              </v-col>
            </v-row>
          </div>


          <!-- section status -->
          <div class="section-divider mt-12">
            <div class="section-divider-title">{{ translations.details }}</div>

            <!-- status -->
            <v-col class="pa-0 mt-1">
              <b class="ml-8">{{ translations.status }}</b>
              <v-select v-model="status" :items="$store.getters.vehicleRequestStatusesSelect"
                        @change="statusChangeHandler"
                        prepend-icon="mdi-progress-check"
                        outlined dense hide-details>
                <template v-slot:selection="{ item }">
                  <v-chip small label class="status-chip" :color="item.background">
                    <div :style="[{ color: item.color }]">{{ item.text }}</div>
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-chip small label class="status-chip" :color="data.item.background">
                    <div :style="[{ color: data.item.color }]">{{ data.item.text }}</div>
                  </v-chip>
                </template>
              </v-select>
            </v-col>


            <v-row v-if="allowDatesSelection" id="dates-row" class="ma-0 pa-0" justify="space-between">
              <!-- date from -->
              <v-col class="pa-0 mt-6" cols="5">
                <b class="ml-8">{{ translations.dateFrom }}</b>
                <date-picker @selected-date="value => setDate(value, 'dateFrom')" :dateProp="dateFrom" :classToAdd="['date-picker-component']" :disablePicker="false"></date-picker>
              </v-col>

              <!-- date to -->
              <v-col class="pa-0 mt-6" cols="6">
                <b class="ml-8">{{ translations.toDate }}</b>
                <date-picker @selected-date="value => setDate(value, 'dateTo')" :dateProp="dateTo" :minDateProp="dateFrom.picker" :startToDate="startToDate" :classToAdd="['date-picker-component']" :disablePicker="false"></date-picker>
              </v-col>
            </v-row>
          </div>
        </v-card-text>


        <!-- invalid form message -->
        <v-row v-if="displayDateError" class="pa-0 mx-0 mt-2" justify="center" align="center">
          <v-icon color="error" class="mr-2" small>mdi-alert</v-icon>

          <span class="error--text" style="font-size: .9rem">{{ translations.fromToDateError }}</span>
        </v-row>


        <v-card-actions class="pb-4 pt-4">
          <v-row class="ma-0" justify="center">
            <v-btn @click="saveVehicleRequest"
                   :disabled="disableBtn" :loading="loading"
                   color="main" class="white--text px-8">
              {{ translations.create }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- message dialog -->
    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
          <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
        </div>
      </template>

      <template v-slot:message>
        <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
          {{ translations.overlappingDatesError }}
        </div>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-center cursor-pointer" style="width: 100%">
          <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </v-row>
</template>


<script>
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import VehicleRequestService from '@/service/vehicleRequest/VehicleRequestService'

import DatePicker from '@/components/DatePicker'
import MessageDialog from '@/components/MessageDialog'
import SmartAutocompleteVehicles from '@/components/SmartAutocompleteVehicles'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'VehicleActivityNew',

  components: {
    DatePicker,
    MessageDialog,
    SmartAutocompleteVehicles,
    WarningSvg
  },

  data() {
    return {
      translations: translations,

      showDialog: false,
      loading: false,
      showErrorMsgDialog: false,

      type: this.$store.getters.vehicleOperations[0].value,
      priority: this.$store.getters.prioritiesSelect[0].value,
      status: this.$store.getters.vehicleRequestStatusesSelect[0].value,
      dateFrom: {
        simple: '',
        holder: '',
        picker: ''
      },
      dateTo: {
        simple: '',
        holder: '',
        picker: ''
      },
      datePlanned: {
        simple: '',
        holder: '',
        picker: ''
      },
      vehicleId: null,
      selectedVehicle: {code: '' , label: ''},
      startToDate: '',
      note: '',
      vehicleList: []
    }
  },

  computed: {
    ...mapState({
      accountId: state => state.auth.userInfo.account.id
    }),

    dateFromIsAfterTo() {
      // if none is present return false
      if (!this.dateFrom.simple && !this.dateTo.simple) {
        return false
      }

      // if only one is present return true we need both or none
      if ((!this.dateFrom.simple && this.dateTo.simple) || (this.dateFrom.simple && !this.dateTo.simple)) {
        return true
      }

      return this.dateFrom.simple > this.dateTo.simple
    },

    displayDateError() {
      return this.dateFrom.simple && this.dateTo.simple && this.dateFromIsAfterTo
    },

    disableBtn() {
      return !this.type || this.dateFromIsAfterTo
    },

    allowDatesSelection() {
      return this.selectedVehicle?.id
        && this.$store.getters.vehicleOperations.find(vo => vo.value === this.type).requestable
        && this.$store.getters.statusToAllowShowDate.includes(this.status)
    }
  },

  mixins: [MomentMixin],

  methods: {
    setVehicle(vehicle){
      this.selectedVehicle = vehicle;
    },

    setDate(value, type) {
      if (type === 'both') {
        this.dateFrom.simple = value.simple
        this.dateFrom.holder = this.momentDate(value.simple, 'DD/MM/YYYY')
        this.dateFrom.picker = this.formatDate(value.simple)

        this.dateTo.simple = value.simple
        this.dateTo.holder = this.momentDate(value.simple, 'DD/MM/YYYY')
        this.dateTo.picker = this.formatDate(value.simple)

        this.startToDate = value.picker
      } else {
        this[type].simple = value.simple
        this[type].holder = this.momentDate(value.simple, 'DD/MM/YYYY')
        this[type].picker = this.formatDate(value.simple)

        if (type === 'dateFrom') {
          this.startToDate = value.picker
        }
      }
    },

    closeHandler() {
      this.type = this.$store.getters.vehicleOperations[0].value
      this.priority = this.$store.getters.prioritiesSelect[0].value
      this.status = this.$store.getters.vehicleRequestStatusesSelect[0].value
      this.dateFrom = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.dateTo = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.datePlanned = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.selectedVehicle = { code: '', label: '' }
      this.note = ''
    },

    async saveVehicleRequest() {
      this.loading = true
      await VehicleRequestService.save({
          vehicleRequestObject: {
            accountId: this.accountId,
            vehicleId: this.selectedVehicle?.id,
            note: this.note,
            type: this.type,
            priority: this.priority,
            status: this.status,
            dateFromStr: !this.allowDatesSelection ? null : this.dateFrom.simple ? this.formatDate(this.dateFrom.simple) : null,
            dateToStr: !this.allowDatesSelection ? null : this.dateTo.simple ? this.formatDate(this.dateTo.simple) : null,
            datePlannedStr: this.datePlanned.simple ? this.formatDate(this.datePlanned.simple) : null
          }
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.showDialog = false
          this.closeHandler()
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
        })
    },

    // when status change check if dates are shown if true scroll to dates row to show
    statusChangeHandler() {
      setTimeout(() => {
        if (this.allowDatesSelection) {
          document.querySelector('#dates-row').scrollIntoView({
            behavior: 'smooth'
          })
        }
      }, 100)
    }
  }
}
</script>
