<template>
  <v-row class="ma-0" justify="center">
    <!-- new request dialog -->
    <v-dialog v-model="showDialog"
              @click:outside="closeHandler"
              @keydown="value => value.key === 'Escape' ? closeHandler() : ''"
              scrollable max-width="700px">
      <template v-slot:activator="{ on }">
        <v-btn @click="showDialog = true" color="main" class="white--text" small depressed v-on="on">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ translations.newButton }}
        </v-btn>
      </template>

      <v-card class="pa-1">
        <!-- title -->
        <v-card-title class="d-flex justify-center">
          <h3 class="main--text">{{ translations.newRequest }}</h3>
        </v-card-title>

        <v-card-text>
          <!-- section employee -->
          <div class="section-divider mt-1">
            <div class="section-divider-title">{{ translations.employee }}</div>
            <v-menu max-height="200px" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field ref="employeeFilter" v-on="on" v-bind="attrs"
                              name="none"
                              placeholder="Cerca addetto"
                              v-model="employeePlaceHolder"
                              :loading="$apollo.queries.employees.loading"
                              autocomplete="off"
                              outlined hide-details dense clearable
                              @click:clear="employeeForFilterSelection(null)"
                              @input="getFilteredEmployees()"
                              prepend-icon="mdi-account">
                </v-text-field>
              </template>
              <v-list>
                <v-list-item v-for="emp in employeeList" :key="emp.id" @click="employeeForFilterSelection(emp)">
                  {{ emp.name }}
                </v-list-item>

                <v-list-item v-if="employeeList.length === 0 && !$apollo.queries.employees.loading">
                  {{ translations.noEmployeesFoundWThatName }}
                </v-list-item>
              </v-list>

              <div v-if="hasNextEmployees && !$apollo.queries.employees.loading" v-intersect="onIntersect" style="background: white;" class="d-flex justify-center">
                <v-progress-circular indeterminate color="main"></v-progress-circular>
              </div>
            </v-menu>
          </div>


          <!-- section type + dates -->
          <div class="section-divider mt-12">
            <div class="section-divider-title">{{ translations.requestType }}</div>

            <div class="d-flex justify-space-between align-start">
              <!-- type -->
              <div>
                <v-radio-group v-model="statusId" class="pa-0 ma-0" hide-details>
                  <v-radio v-for="requestType in requestTypes" :key="requestType.id" :label="requestType.type" :value="requestType.id" hide-details dense class="mt-2"></v-radio>
                </v-radio-group>
              </div>

              <!-- dates -->
              <div class="mr-12" style="width: 50%">
                <!-- additional input when day part is empty -->
                <template v-if="statusId && !selectedRequestHaveDayPart">
                  <!-- date from -->
                  <v-col class="pa-0 mt-0">
                    <b>{{ translations.dateFrom }}</b>

                    <date-picker @selected-date="(value) => setDate(value, 'dateFrom')"
                                 :key="`a-${counter}`"
                                 :dateProp="dateFrom"
                                 :classToAdd="['date-picker-component']"
                                 :clearFields="clearFields">
                    </date-picker>
                  </v-col>

                  <!-- date to -->
                  <v-col class="pa-0 mt-6">
                    <b>{{ translations.toDate }}</b>

                    <date-picker @selected-date="(value) => setDate(value, 'dateTo')"
                                 @cleared-fields="clearFields = false"
                                 :key="`b-${counter}`"
                                 :dateProp="dateTo"
                                 :minDateProp="dateFrom.simple"
                                 :startToDate="startToDate"
                                 :classToAdd="['date-picker-component']"
                                 :clearFields="clearFields">
                    </date-picker>
                  </v-col>
                </template>

                <!-- additional input when day part is not empty -->
                <template v-else-if="statusId && selectedRequestHaveDayPart">
                  <!-- date -->
                  <v-col class="pa-0 mt-0">
                    <b>{{ translations.date }}</b>

                    <date-picker @selected-date="(value) => setDate(value, 'both')"
                                 @cleared-fields="clearFields = false"
                                 :key="`b-${counter}`"
                                 :dateProp="dateTo"
                                 :minDateProp="dateFrom.simple"
                                 :startToDate="startToDate"
                                 :classToAdd="['date-picker-component']"
                                 :clearFields="clearFields">
                    </date-picker>
                  </v-col>

                  <!-- day part -->
                  <v-col class="pa-0 mt-6">
                    <b>{{ translations.dayPart }}</b>

                    <v-select v-model="dayPart" :items="$store.getters.activityRequestDayParts"
                              placeholder="Selezionare..." prepend-icon="mdi-circle-half-full"
                              outlined dense hide-details max-width="180px" />
                  </v-col>
                </template>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="pb-4 pt-4">
          <v-row class="ma-0" justify="center">
            <v-btn @click="activityRequestHandler" :disabled="disableBtn" color="main" class="white--text px-8" :loading="loading">{{ translations.confirm }}</v-btn>

            <!-- invalid form message -->
            <v-row v-if="displayDateError" class="pa-0 mt-2" justify="center" align="start">
              <v-col class="pa-0" cols="auto">
                <v-icon color="error" class="mr-2" small>mdi-alert</v-icon>
              </v-col>

              <v-col class="pa-0">
                <span class="error--text" style="font-size: .9rem">{{ translations.fromToDateError }}</span>
              </v-col>
            </v-row>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- message dialog -->
    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
          <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
        </div>
      </template>

      <template v-slot:message>
        <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
          {{ translations.overlappingDatesError }}
        </div>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-center cursor-pointer" style="width: 100%">
          <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </v-row>
</template>


<script>
import debounce from 'lodash/debounce'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import ActivityRequestService from '@/service/activityRequest/ActivityRequestService'

import employeesQuery from '@/graphql/Employees.gql'

import DatePicker from '@/components/DatePicker'
import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'ActivityRequestNew',

  components: {
    DatePicker,
    MessageDialog,
    WarningSvg
  },

  apollo: {
    employees: {
      query: employeesQuery,
      variables() {
        return {
          page: this.page,
          pageSize: 20,
          sort: 'name',
          order: 'asc',
          name: this.employeeToSearch,
          dateStr: this.today
        }
      },
      fetchPolicy: 'no-cache',
      result({ data }) {
        const { content, hasNext } = data.employees

        this.employeeList.push(...content)
        this.hasNextEmployees = hasNext
      }
    }
  },

  props: {
    requestInfo: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    return {
      translations: translations,

      showDialog: false,
      loading: false,
      showErrorMsgDialog: false,

      statusId: '',
      counter: 0,
      dateFrom: {
        simple: '',
        holder: '',
        picker: ''
      },
      dateTo: {
        simple: '',
        holder: '',
        picker: ''
      },
      employeeId: '',
      employeeToSearch: '',
      employeePlaceHolder: '',
      startToDate: '',
      activityContent: [],
      clearFields: false,
      dayPart: null,
      page: 1,
      hasNextEmployees: false,
      employeeList: []
    }
  },

  created() {
    if (!this.requestInfo?.dateFrom) return
    this.prepInfo()
  },

  computed: {
    dateFromIsAfterTo() {
      if (!this.dateFrom.simple || !this.dateTo.simple) return true

      return this.dateFrom.simple > this.dateTo.simple
    },

    displayDateError() {
      return this.dateFrom.simple && this.dateTo.simple && this.dateFromIsAfterTo
    },

    requestTypes() {
      if (this.$store.getters.employeeStatuses.length === 0) return []

      return this.$store.getters.employeeStatuses.map(status => {
        return {
          id: status.id,
          type: status.description,
          dayPart: status.dayPart
        }
      })
    },

    selectedRequestHaveDayPart() {
      if (!this.statusId) return ''

      const requestType = this.requestTypes.find(req => req.id === this.statusId)

      return requestType.dayPart
    },

    needsAndHasDayPart() {
      if (!this.statusId) return false
      if (!this.selectedRequestHaveDayPart) return true
      return !!(this.selectedRequestHaveDayPart && this.dayPart)
    },

    disableBtn() {
      return !this.statusId || !this.dateFrom.simple || !this.dateTo.simple || !this.employeeId || !this.needsAndHasDayPart || this.dateFromIsAfterTo
    },

    today() {
      return this.getSimpleDay().slice(0, 10)
    }
  },

  mixins: [MomentMixin],

  methods: {
    setDate(value, type) {
      if (type === 'both') {
        this.dateFrom.simple = value.simple
        this.dateFrom.holder = this.momentDate(value.simple, 'DD/MM/YYYY')
        this.dateFrom.picker = this.formatDate(value.simple)

        this.dateTo.simple = value.simple
        this.dateTo.holder = this.momentDate(value.simple, 'DD/MM/YYYY')
        this.dateTo.picker = this.formatDate(value.simple)

        this.startToDate = value.picker
      } else {
        this[type].simple = value.simple
        this[type].holder = this.momentDate(value.simple, 'DD/MM/YYYY')
        this[type].picker = this.formatDate(value.simple)

        if (type === 'dateFrom') {
          this.startToDate = value.picker
        }
      }
    },

    closeHandler() {
      this.statusId = ''
      this.dateFrom = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.dateTo = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.employeeId = ''
      this.employeeToSearch = ''
      this.employeePlaceHolder = ''
      this.counter++
      this.activityContent = []
      this.dayPart = null
    },

    activityRequestHandler() {
      const activityRequestObject = {
        dateFromStr: this.dateFrom.simple ? this.formatDate(this.dateFrom.simple) : null,
        dateToStr: this.dateTo.simple ? this.formatDate(this.dateTo.simple) : null,
        employeeId: this.employeeId,
        employeeStatus: {
          id: this.statusId,
          keyCode: this.$store.getters.employeeStatuses.find(req => req.id === this.statusId).keyCode
        }
      }

      if (this.dayPart && this.selectedRequestHaveDayPart) {
        activityRequestObject.dayPart = this.dayPart
      }

      this.sendRequestHandler(activityRequestObject)
    },

    async sendRequestHandler(activityRequestObject) {
      this.loading = true
      await ActivityRequestService.save({
          activityRequestObject
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.showDialog = false
          this.loading = false
          this.closeHandler()
        })
    },

    prepInfo() {
      const { dateFrom, dateTo, employeeStatus } = this.requestInfo
      //Simple
      this.dateFrom.simple = dateFrom
      this.dateTo.simple = dateTo
      //Holder text field
      this.dateFrom.holder = this.momentDate(dateFrom, 'DD/MM/YYYY')
      this.dateTo.holder = this.momentDate(dateTo, 'DD/MM/YYYY')
      //Picker
      this.dateFrom.picker = this.formatDate(dateFrom)
      this.dateTo.picker = this.formatDate(dateTo)
      this.statusId = employeeStatus.id
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.page++
      }
    },

    employeeForFilterSelection(employee) {
      if (!employee) {
        this.$refs.employeeFilter.blur()

        this.employeeId = ''
        this.employeePlaceHolder = ''
        return
      }
      this.employeeId = employee.id
      this.employeePlaceHolder = employee.name
    },

    getFilteredEmployees: debounce(function() {
      this.employeeList = []
      this.page = 1
      this.employeeToSearch = this.employeePlaceHolder
    }, 650)
  }
}
</script>
