<template>
  <main class="ma-5">
    <v-tabs v-model="tabSelected">
      <v-tab v-for="tab in tabs()" :key="tab.route" @click="updateRoute(tab.route)">
        <v-icon class="mr-1">{{ tab.icon }}</v-icon>
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabSelected" class="elevation-2">
      <v-tab-item>
        <ActivityRequest />
      </v-tab-item>

      <v-tab-item>
        <VehicleRequest />
      </v-tab-item>

      <v-tab-item>
        <Dpi />
      </v-tab-item>
    </v-tabs-items>
  </main>
</template>


<script>
import { translations } from '@/utils/common'

import ActivityRequest from '@/views/ActivityRequest/ActivityRequest'
import VehicleRequest from '@/views/VehicleRequest/VehicleRequest'
import Dpi from '@/views/Dpi/Dpi'

export default {
  name: 'BackOffice',

  components: {
    ActivityRequest,
    VehicleRequest,
    Dpi
  },

  data() {
    return {
      translations: translations,

      tabSelected: 0
    }
  },

  mounted() {
    const tabs = this.tabs()
    const index = tabs.findIndex(tab => tab.route === this.$route.params.tab)
    if (index === -1) {
      this.updateRoute(tabs[0].route)
    } else {
      this.tabSelected = index
    }
  },

  methods: {
    tabs() {
      const tabs = []

      tabs.push({
        route: 'activity-request',
        title: translations.employeeRequest,
        icon: 'mdi-clipboard-account'
      })

      tabs.push({
        route: 'vehicle-request',
        title: translations.vehicleRequest,
        icon: 'mdi-clipboard-flow'
      })

      tabs.push({
        route: 'dpi',
        title: translations.dpi,
        icon: 'mdi-hard-hat'
      })

      return tabs
    },

    updateRoute(route) {
      this.$router.push('/back-office/' + route)
    }
  }
}
</script>


<style>
.v-tabs > .v-tabs-bar {
  background: none !important;
}
</style>
